.select2-selection--multiple {
    border: none !important;
}

select.select2-select {
    height: 44px;
    visibility: hidden;
}

.select2-search__field:focus {
  outline: none;
}

.select2-selection--multiple .select2-selection__choice {
    margin-top: 2px !important;
}
.select2-container.select2-container--default {
    height: 100% !important;
    min-height: 44px !important;
    padding-bottom: 0px !important;
}
.input-group .select2-container {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
