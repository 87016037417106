.dropdown-submenu {
    position: relative;
    &>.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: 0px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
    }
    &:hover>.dropdown-menu {
        display: block;
    }
    &>a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }
    &:hover>a:after {
        border-left-color: #fff;
    }
    &.pull-left {
        float: none;
    }
    &.pull-left>.dropdown-menu {
        left: -100%;
        margin-left: 10px;
    }
}
