.btn-collapse .collapse-hide {
    display: inherit;
}

.btn-collapse .collapse-show {
    display: none;
}

.btn-collapse.collapsed .collapse-hide {
    display: none;
}

.btn-collapse.collapsed .collapse-show {
    display: inherit;
}