
.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 20px;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 44px;
    /*padding: 6px 16px;*/
    margin: 0;
    opacity: 0;
}

.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 44px;
    padding: 10px 16px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 14px .75rem;
    font-size: 14px;
    line-height: 1;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
}

.custom-file-label {
    overflow: hidden;
}

.custom-file-label::after {
    content: "Buscar";
}

.custom-file-input {
    cursor: pointer;
}

