/* CSS PARA BOTON FLOTANTE */
body_flotante{
    width: 100%;
    height: 100%;
}

.btn-group-sm_flotante .btn-fab_flotante{
    position: fixed !important;
    right: 10%;
    border-radius:100%;
    height: 68px;
    padding-bottom: 25px;
    padding-top: 25px;
}

.btn-group_flotante .btn-fab_flotante{
    position: fixed !important;
    right: 10%;
    border-radius: 100%;
    height: 68px;
    padding-bottom: 25px;
    padding-top: 25px;
}

#main_flotante{
    bottom: 20px;
    z-index: 99999;
}

#csv_flotante{
    bottom: 180px;
    z-index: 99999;
}

#add_content_flotante{
    bottom: 100px;
    z-index: 99999;
}

.icono-flotante{
    width: 18px;
    height: 18px;
    color:black;
}