@import "app_poncho";
@import "float_button";

@import "collapse-toggle";
@import "custom-file-input";
@import "dropdown-submenu";
@import "poncho-select2";

.select2.select2-container--open {
    border: solid 1px #ddd !important;
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.resumable-hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top:0px;
    left: 0px;
    width: 1px;
    height: 1px;
}

